<template>
    <div>
        <ek-table
            :columns="columns"
            :items="dashUserList"
            @delete-selected="deleteUsers"
            @details="detailsAppPanel"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.isBlocked" slot-scope="{ value }">
                <StatusBadge :value="value" :options="dashUserStatusList" />
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";
export default {
    components: {
        StatusBadge
    },
    computed: {
        ...mapState({
            columns: state => state.dashboardUsers.columns,
            dashUserList: state => state.dashboardUsers.dashUserList,
            dashUserStatusList: state => state.dashboardUsers.dashUserStatus
        })
    },
    methods: {
        detailsAppPanel({ row }) {
            this.$router.push(`/admin/dashboardUsers/${row.id}`);
        },
        deleteUsers(e) {
            this.$store.dispatch("deleteRangeDashUser", e);
        }
    },
    created() {
        this.$store.dispatch("getDashUser");
    }
};
</script>

<style></style>
